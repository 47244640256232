<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="修改接运地点"
      @ok="handleSubmit"
      width="700px"
    >
      <a-form
        class="custom-compact-form"
        :form="form"
        :label-col="{ span: 7 }"
        :wrapper-col="{ span: 13 }"
        @submit="handleSubmit"
      >
        <a-form-item label="地址检索">
          <a-input-search
            style="width: 100%;"
            v-decorator="['address_search', {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入地点名称' },
                { max: 6, message: '最多6个字符' },
              ]
            }]"
            enter-button="定位"
            @search="location"
          />
        </a-form-item>
        <a-form-item label="接运地点">
          <a-select
            v-decorator="['origination', {
              rules: [
                { required: true, message: '请选择接运地点' },
              ]
            }]"
            placeholder="请选择接运地点"
            @change="changLocation"
          >
            <a-select-option
              v-for="(location,index) in locationOptions"
              :key="index"
              :value="index"
            >
              {{ location.title }}({{ location.address }})
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="详细地址">
          <a-input
            v-decorator="['origination_detail', {
              rules: [
                { max: 100, message: '最多100个字符'},
              ]
            }]"
            autocomplete="off"
          />
        </a-form-item>
      </a-form>
      <div ref="container" />
    </a-modal>
  </div>
</template>

<script>
import { locationSearch } from '@/api/map'
import { findOrderInfo, updateTransportation } from '@/api/order'

export default {
  name: 'UpdateTransportation',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    orderId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'update_transportation' }),
      submitting: false,
      locationOptions: [],
      destinationOptions: [],
      createMap: true,
      longitude: 0,
      latitude: 0,
      address: '',
      locationTitle: '',
      serviceOrderId: 0
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.fetchServiceOrderInfo()
  },
  methods: {
    fetchServiceOrderInfo() {
      findOrderInfo(this.orderId).then(res => {
        if (res.code === 0) {
          this.form.setFieldsValue({
            origination: res.data.origination_info.origination,
            origination_detail: res.data.origination_info.origination_detail
          })
          this.longitude = res.data.origination_info.longitude
          this.latitude = res.data.origination_info.latitude
          this.serviceOrderId = res.data.record_id
          this.address = res.data.origination_info.origination
        }
      })
    },

    handleSubmit() {
      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          const data = {
            ...values
          }
          const saveData = Object.assign(data,
            {
              longitude: this.longitude,
              latitude: this.latitude,
              origination: this.address
            })
          updateTransportation(this.serviceOrderId, saveData).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    },
    initMap() {
      const center = new TMap.LatLng(31.981234, 120.894518)
      const map = new TMap.Map(this.$refs.container, {
        center: center,
        zoom: 13
      })
      const marker = new TMap.MultiMarker({
        map: map,
        styles: {
          current: new TMap.MarkerStyle({
            width: 34,
            height: 46,
            anchor: { x: 36, y: 32 },
            src: 'https://res.anxiaotang.com/assets/d273876b-2874-4d50-bf7a-7d623845c0e8',
            color: '#333',
            size: 16,
            direction: 'bottom',
            strokeColor: '#fff',
            offset: { x: 0, y: 0 },
            strokeWidth: 2
          })
        },
        geometries: []
      })
      this.marker = marker
      this.amap = map
      this.createMap = false
    },
    changLocation(val) {
      const location = this.locationOptions[val]
      this.longitude = location.location.lng
      this.latitude = location.location.lat
      this.locationTitle = location.title
      this.address = this.locationTitle
      this.form.setFieldsValue({ origination_detail: location.address })
      this.initMarkers(location.title, location.location.lat, location.location.lng)
    },
    initMarkers(title, lat, lng) {
      const currentLatLng = new TMap.LatLng(lat, lng)
      this.marker.updateGeometries([
        {
          styleId: 'current',
          id: '1',
          position: currentLatLng,
          content: title // 标注点文本
        }
      ])
      this.amap.setCenter(currentLatLng)
    },
    location() {
      this.onSearch()
      if (this.createMap === true) {
        this.initMap()
      }
    },
    onSearch() {
      const name = this.form.getFieldValue('address_search')
      locationSearch({ keyword: name, page_size: 10 }).then(res => {
        if (res.code === 0 && res.data.status === 0) {
          this.locationOptions = res.data.data
        }
      })
    }
  }
}
</script>

<style scoped>
  .margin-left-10 {
    margin-left: 10px;
  }
</style>
